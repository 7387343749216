import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
//import { VueReCaptcha } from 'vue-recaptcha-v3'


createApp(App).use(router).mount('#app')

//const app = createApp(App).use(router).use(VueReCaptcha, { siteKey: '6LcJ5hsfAAAAAEg-0biD9KjH-wu3yS0dy4fyUUwa' })
//app.mount('#app')