<template>
  <div>
    <div class="dialog">
      <a href="#" v-on:click="closeWindow" class="close-thik"></a>
    </div>
    <div class="text-center">
      <img alt="BSI Logo" src="../assets/bsi-logo.png" />

      <!--<img class="d-block mx-auto mb-4" src="../assets/brand/bootstrap-logo.svg" alt="" width="72" height="57">-->
      <h1 class="display-5 fw-bold">New user registration page</h1>
     
      <div class="col-lg-6 mx-auto">
        <!---->
        <vue-cookie-accept-decline
          :debug="false"
          :disableDecline="true"
          :showPostponeButton="false"
          elementId="myPanel1"
          position="bottom"
          ref="myPanel1"
          transitionName="slideFromBottom"
          type="bar"
        >
          <!-- Optional -->
          <template #postponeContent>&times;</template>

          <!-- Optional -->
          <template #message>
            <span
              >By clicking, you accept that this website stores personal
              information for the duration of the contest to track fair
              participation. To make an inquiry about your stored data write to
            </span>
            <a
              style="
                margin-left: 10px;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                border-radius: 8px;
                padding: 5px;
                background: #389ce9;
                text-decoration: none;
                color: #fff;
              "
              :href="'https://t.me/' + telegramContactName"
              target="_blank"
              ><svg
                style="
                  width: 30px;
                  height: 20px;
                  vertical-align: middle;
                  margin: 0px 5px;
                "
                viewBox="0 0 21 18"
              >
                <g fill="none">
                  <path
                    fill="#ffffff"
                    d="M0.554,7.092 L19.117,0.078 C19.737,-0.156 20.429,0.156 20.663,0.776 C20.745,0.994 20.763,1.23 20.713,1.457 L17.513,16.059 C17.351,16.799 16.62,17.268 15.88,17.105 C15.696,17.065 15.523,16.987 15.37,16.877 L8.997,12.271 C8.614,11.994 8.527,11.458 8.805,11.074 C8.835,11.033 8.869,10.994 8.905,10.958 L15.458,4.661 C15.594,4.53 15.598,4.313 15.467,4.176 C15.354,4.059 15.174,4.037 15.036,4.125 L6.104,9.795 C5.575,10.131 4.922,10.207 4.329,10.002 L0.577,8.704 C0.13,8.55 -0.107,8.061 0.047,7.614 C0.131,7.374 0.316,7.182 0.554,7.092 Z"
                  ></path>
                </g></svg
              >Telegram</a
            >
          </template>

          <!-- Optional -->
          <template #declineContent>Opt Out</template>

          <!-- Optional -->
          <template #acceptContent>Got It!</template>
        </vue-cookie-accept-decline>

        <p class="lead mb-4" id="errorMessage" v-if="errorMessage">
          {{ errorMessage }}
        </p>

        <div v-else>
          <div v-if="userSessionInitalized">
            <div id="recaptcha" v-if="!captchaSolved">
              <vue-recaptcha
                ref="myRecaptcha"
                @verify="verifyCaptcha"
                @expired="expireCaptcha"
                :sitekey="captchaSiteKey"
              >
              </vue-recaptcha>
            </div>

            <div v-if="captchaSolved">
              <b id="helloUser">Hello {{ username }}</b>

              <div
                class="lead mb-4"
                id="alreadyMember"
                v-if="isValidTelegramChatMember"
              >
                You are already a member of the DegenX tg group chat so nothing to do.
              </div>

              <div class="lead mb-4" id="memberOfChat" v-else>
                You are currently not a member of the DegenX tg group chat.
                <p class="lead mb-4">
                  Please click the blue button below to be directed to the
                  telegram group. When the Telegram group has opened click on
                  the JOIN link at the bottom of the page to join the group (see
                  image below).
                </p>
                <br />
                <div v-if="!showQueryMembershipProgress" class="center-block">
                  <img
                    alt="Join Chat Logo"
                    src="../assets/joinchat.png"
                    width="300"
                  />
                </div>
                <div v-else>
                  <div
                    class="lead mb-4"
                    id="waitMessage"
                    v-if="!currentIterationIsMember && !timeoutQuerying"
                  >
                    Wait until user {{ username }} has joined telegram group...
                    Do NOT close this windows until a SUCCESS message appears!
                    <div class="loader"></div>
                  </div>
                  <div
                    class="lead mb-4"
                    id="thankyouJoining"
                    v-else-if="currentIterationIsMember && !timeoutQuerying"
                  >
                    Thank you for joining the DegenX tg group. You can
                    close this window now if you like.
                  </div>
                  <div
                    class="lead mb-4"
                    v-else-if="!currentIterationIsMember && timeoutQuerying"
                  >
                    Timed out trying to find out if joining the group has been
                    successful.
                  </div>
                </div>
                <br />
                <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                  <button
                    class="btn btn-primary btn-lg px-4 gap-3"
                    :disabled="disableButton"
                    id="joinTelegramGroupButton"
                    v-on:click="startConfirmationProcess"
                    type="button"
                  >
                    Open DegenX group
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div class="lead mb-4" v-else>
            Initalizing session
            <div class="loader"></div>
          </div>
        </div>
      </div>
      <br/>    
      <p class="lead mb-4">
        You can win a big bag of BASAN token by joining the Official DegenX Telegram 
        Referral Comp! More information about the competition can be found in the chat after 
        registering.
      </p>
      <div class="text-center">
        <img
          alt="BSI referral contest"
          class="img-fluid"
          :src="require('../assets/bsi-referral-contest.jpeg')"
        />
      </div>
      <!---->
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
// For more options see below

export default {
  name: "RegisterNewUser",
  data: function () {
    return {
      errorMessage: null,
      username: null,
      encryptedId: null,
      checkAlreadyMember: null,
      baseUrl: process.env.VUE_APP_ROOT_API_URL,
      captchaSiteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      telegramGroupName: process.env.VUE_APP_TELEGRAM_GROUP_NAME,
      telegramContactName: process.env.VUE_APP_TELEGRAM_CONTACT_NAME,
      captchaSolved: false,
      isInitalValidTelegramChatMember: null,
      isCurrentValidTelegramChatMember: null,
      chatName: null,
      showQueryMembershipProgress: false,
      isValidTelegramChatMember: null,
      currentIterationIsMember: null,
      timeoutQuerying: false,
      userSessionInitalized: null,
      queryTelegramMembershipRepeats: 10,
      queryTelegramMembershipEveryMinute: 2,
      recaptchaToken: false,
      disableButton: false,
    };
  },
  components: { VueRecaptcha, VueCookieAcceptDecline },
  methods: {
    closeWindow: function () {		
			// https://stackoverflow.com/questions/38051490/how-to-close-native-browser-in-telegram-programmatically
      // window.location.href = "tg:resolve";
      window.location.href = "https://t.me/BasanNewUserBot"

    },
    // add user to database if valid, also start telegram query on
    // server side
    async registerNewUserToReferral() {
      this.saveSessionStep("START_REGISTER_NEWREFERRAL");

      if (this.encryptedId) {
        let mydata = (
          await axios.post(this.baseUrl + "/api/v1/newUser/referNewUser", {
            encryptedId: this.encryptedId,
            recaptchaToken: this.recaptchaToken,
          })
        ).data;

        if (mydata.error) {
          this.errorMessage = mydata.message;
          // store error message
          this.saveSessionStep(
            "ERROR_" + this.errorMessage.replace(/ /g, "_").toUpperCase()
          );
        } else {
          this.saveSessionStep("SUCCESS_REGISTER_NEWREFERRAL");
          this.errorMessage = null;


          // begin query database (from server side)
          // this will not diretly query telegram api
          // all api querying is done on server side
          this.queryTelegramMembershipPeriod();

          // now open telegram link in new tab
          // TODO: get rid of hardcoded value
          // ios / chrome has problems with opening links using window.open
          // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
          setTimeout(() => {
            window.open("https://t.me/" + this.telegramGroupName, "_blank"); //.focus();
          });
        }
      }
    },
    async queryTelegramMembershipPeriod() {
      // check if we are currently a telegram chat member
      this.saveSessionStep("START_QUERY_TELEGRAMMEMBER");

      if (this.encryptedId) {
        let currentIteration = 0;
        let maxIterations = this.queryTelegramMembershipRepeats;

        let intervalId = setInterval(() => {
        
          let mydata = axios
            .get(
              this.baseUrl +
                "/api/v1/newUser/queryIsReferredNewUser/" +
                this.encryptedId
            )
            .then((myobj) => {
              mydata = myobj.data;

              if (mydata.error) {
                this.errorMessage = mydata.message;
                this.saveSessionStep(
                  "ERROR_" + this.errorMessage.replace(/ /g, "_").toUpperCase()
                );
              } else {
                this.errorMessage = null;
                this.currentIterationIsMember = mydata.data.isReferredNewUser;
                // if the user joined the chat using this webpage
                // after he clicked on the button :
                // THIS is the only allowed way to get a point for the referral!!!
                if (this.currentIterationIsMember) {
                  this.saveSessionStep("SUCCESS_QUERY_TELEGRAMMEMBER");

                  this.timeoutQuerying = false;
                }
              }

              // stop interval after reaching some iterations
              if (++currentIteration > maxIterations) {
                this.saveSessionStep("TIMEOUT_DID_NOT_JOIN_IN_TIME");
                this.timeoutQuerying = true;
                clearInterval(intervalId);
              }
            });
        }, this.queryTelegramMembershipEveryMinute * 60 * 1000); // end of setInterval
      }
    },
    async getValidTelegramChatMemberUsername() {
      // check if we are currently a telegram chat member
      this.saveSessionStep("START_VALID_TELEGRAM");

      if (this.encryptedId) {
        let mydata = (
          await axios.get(
            this.baseUrl +
              "/api/v1/newUser/getValidTelegramChatMemberUsername/" +
              this.encryptedId
          )
        ).data;

        if (mydata.error) {
          this.errorMessage = mydata.message;
          this.saveSessionStep(
            "ERROR_" + this.errorMessage.replace(/ /g, "_").toUpperCase()
          );
        } else {
          this.saveSessionStep("SUCCESS_VALID_TELEGRAM");
          this.errorMessage = null;
          this.isValidTelegramChatMember =
            mydata.data.isValidTelegramChatMember;
        }
      }
    },
    async saveSessionStep(step) {
      if (this.encryptedId) {
        let mydata = (
          await axios.get(
            this.baseUrl +
              "/api/v1/newUser/addNewUserStep/" +
              this.encryptedId +
              "/" +
              step
          )
        ).data;

        if (mydata.error) {
          console.log(mydata);
        }
      }
    },
    async verifyCaptcha(tokenResponse) {
      //console.log(tokenResponse);
      this.saveSessionStep("CAPTCHA_SOLVED");
      await this.getValidTelegramChatMemberUsername();
      this.captchaSolved = true;
      this.displayCaptcha = false;
      // store current recaptcha solved captcha token
      // so we can validate it again on server side in each request following this
      this.recaptchaToken = tokenResponse;
    },
    expireCaptcha() {
      //this.$refs.recaptcha.reset() // Direct call reset method
    },
    resetCaptcha() {
      this.$refs.myRecaptcha.reset(); // Direct call reset method
    },
    async checkEncryptedUserId() {
      // check if id is correctly encrypted
      let mydata = (
        await axios.get(
          this.baseUrl +
            "/api/v1/newUser/validateNewUserEncryptedId/" +
            this.encryptedId
        )
      ).data;

      return mydata;
    },
    async initUserSessionAndReturnMembership() {
      this.saveSessionStep("START_INIT_USERSESSION");

      // init usersession (if not already exists)
      let mydata = (
        await axios.get(
          this.baseUrl + "/api/v1/newUser/initUsersession/" + this.encryptedId
        )
      ).data;

      if (mydata.error) {
        this.errorMessage = mydata.message;
        this.saveSessionStep(
          "ERROR_" + this.errorMessage.replace(/ /g, "_").toUpperCase()
        );
      } else {
        this.saveSessionStep("SUCCESS_INIT_USERSESSION");

        this.errorMessage = null;

        // inital (first time we ever contacted this webpage) vs. current membership
        this.isInitalValidTelegramChatMember =
          mydata.data.isInitalValidTelegramChatMember;
        this.isCurrentValidTelegramChatMember =
          mydata.data.isCurrentValidTelegramChatMember;

        this.chatName = mydata.data.chatName;
      }
    },
    async startConfirmationProcess() {
      this.saveSessionStep("START_CONFIRMATION_PROCESS");

      // show progress wheel
      this.showQueryMembershipProgress = true;

      // register new referral, this will also start querying
      // the telegram api for membership on server side
      this.registerNewUserToReferral();
      this.disableButton = true;
    },
  },

  // TODO: do output all errors to user

  // http://localhost:8084/inspectUser/1977979186
  mounted: async function () {
    // this must be the first expression ever...we need it in every method
    this.encryptedId = this.$route.params.encryptedId;

    this.saveSessionStep("START_USERIDCHECK").catch((error) =>
      console.log(error)
    );

    if (!this.encryptedId) {
      return;
    }

    // check encrypted user id
    let mydata = await this.checkEncryptedUserId();
    if (mydata.error) {
      this.errorMessage = mydata.message;
      this.saveSessionStep(
        "ERROR_" + this.errorMessage.replace(/ /g, "_").toUpperCase()
      );
    }
    // if data could be decrypted
    else {
      this.saveSessionStep("SUCCESS_USERIDCHECK");

      this.errorMessage = null;
      this.username = mydata.data.username;

      // init the user session and check if user is currently member of chat
      await this.initUserSessionAndReturnMembership();

      this.userSessionInitalized = true;
      // check if user was member before (is member of database)

      // check if referral is currently a member of chat

      // check if referral was member before

      // add a page title
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#recaptcha {
  width: 304px;
  margin: 0 auto;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .rounded-lg-3 {
    border-radius: 0.3rem;
  }
}


[class*='close-'] {
  color: #777;
  font: 25px/100% arial, sans-serif;
  position: absolute;
  right: 5px;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  top: 5px;
}

.close-thik:after {
  content: '✖'; /* UTF-8 symbol */
}

</style>
